.customField {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.CustomExtendedDataField_customField__qwZyL#pub_displayNameID{
  height: 10px;
}