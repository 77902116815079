@import '../../styles/customMediaQueries.css';

.namedLinkContainer {
    display: inline-block;
    position: relative;
}

.namedLinkContainer a {
   text-decoration: none;
}
  
.namedLinkContainer a:hover {
   text-decoration: none;
}
  
  
.activeUnderline {
    /* position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: var(--marketplaceColor);  */
    color: green;
}

.activeLinkGreen {
    color: var(--marketplaceColor);
}